import React, { useState, useEffect, useCallback } from 'react';
import {withI18n} from "@lingui/react";
import {getLocaleFromPath} from "utils/i18n.js";
import Layout from "components/layout/layout";
import Footer from "components/layout/footer";
import Header from "components/layout/header";
import {Link} from "gatsby";
import axios from "axios";
import 'animate.css';




const IndexPage = ({pageContext, location}) => {

    const locale = getLocaleFromPath(location.pathname);
    const [isOpen, setIsOpen] = useState(false);
    const [currentPhoto, setCurrentPhoto] = useState([null, null, null]);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [offset, setOffset] = useState(0);
    const [photos, setPhotos] = useState(null);
    const filters = ["jelen", "diviak", "srnec", "polievka", "chilli"];
    const active_filter = pageContext.page_filter;
    
    
    function getRecipes() {
      axios({
        method: "get",
        url: process.env.DIRECTUS_URL + "items/Recipe?fields=Title,Text,tags.Tags_id,Image&limit=15&filter[tags][Tags_id]="+ active_filter + "&offset=" + offset,
        headers :{
            'Content-Type': 'application/json',
            'Authorization': process.env.BEARER_TOKEN,
            'Access-Control-Allow-Origin': process.env.DIRECTUS_URL
        }
    }).then((result)=>{
      
      let items = result.data.data.map(( object , index) => (
  
        {
          src: process.env.DIRECTUS_URL+ "assets/" + object.Image,
          title: object.Title,
          text: object.Text
        }
  ));
  setPhotos(items)
    })
    .catch((error)=>{
        console.log(error);
    });
  };
    
    useEffect(() => {
      // if (photos == null) {
        

        getRecipes();
      // }

    }, [offset]);
    

    
    function closeModal() {
      setIsOpen(false);
    };
    
    const increaseOffset = useCallback( () => {
      setOffset(offset+10);
        getRecipes();
      },
      [offset],
     );
     
    
    function decreaseOffset() {
      setOffset(offset-10);
      getRecipes();
    };
    
    function openModal(title, text, photo) {
      setCurrentPhoto([title, text, photo]);
      setIsOpen(true);
    };

    return (
        <Layout location={location}>
            <div className="MainContent animate__animated animate__fadeIn">
                <section id="contact">
                <div className="container">
                        <h2>Recepty</h2>
                        <div className="c100p">
                        <div className="buttonHolder">
                <Link className="button" to={"/"+locale+"/recepty"}>Všetko</Link>
                        {filters.map((x) =>
             <Link className="button" activeStyle={{ color: "red" }} to={"/"+locale+"/recepty/"+x}>{x}</Link>
          )}
          </div>
          <br/>
                        <div>
      
      {photos ? photos.map((file, index) => {
                            return (
                                <div className="c20p animate__animated animate__fadeIn" key={`recipe-${index}`}>
                                    <img className="recipe-image animate__animated animate__fadeIn" src={file.src} alt={file.title} onClick={() => openModal(file.title, file.text, file.src)} />
                                </div>
                            )
                            }):null}
      
      
      

<div>
  {isOpen && photos
    ? <div className="Modal animate__animated animate__fadeIn">
       <div className="c50p pt-20">
          <h3>{currentPhoto[0]}</h3>
          <div dangerouslySetInnerHTML={{__html: currentPhoto[1]}}></div> 
          <div className="exit-modal" onClick={() => closeModal()}>✖</div>
        </div>
       <div className="c50p"><img className="recipe-image-detail float-right" src={currentPhoto[2]} alt={currentPhoto[0]} /></div>
      </div>
    : null}
</div>
<div>
  {isOpen
    ? <div className="Overlay"></div>
    : <div></div>}
</div>


    </div>
    <div className="cleaner">&nbsp;</div>
    <div className="buttonHolder">
               {(offset != 0) ?  <a className="cursor-pointer" onClick={() => decreaseOffset()}>Novšie</a> :null} 
              {(offset != 0) && photos && photos.length >= 15 ?  "/" :null} 
              {photos && photos.length >= 15 ?  <a className="cursor-pointer" onClick={increaseOffset}>Staršie</a> :null} 

          </div>
                        </div>
                        <div className="cleaner">&nbsp;</div>
                    </div>

                </section>
            </div>
        </Layout>
    );
};

export default withI18n()(IndexPage);
